(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/utils.js');
"use strict";


const {
  Currency: currency
} = svs.utils.format;
const {
  BOMBEN,
  FULLTRAFF,
  T8,
  T8EXTRA,
  T8STRYK,
  POWERPLAY
} = svs.utils.products.productIds;
const {
  DRAW_STATE
} = svs.components.tipsen.drawCard.constants;
const {
  DrawState,
  DisableType
} = svs.components.sport.tipsenShared;
const {
  SportTypes
} = svs.components.sport.sportTypes;
const {
  drawStateEnums,
  getDrawStateEnum
} = svs.components.sport.tipsenShared;
const {
  selectAllParticipants
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const getIconBySportType = sportTypeId => {
  switch (sportTypeId) {
    case SportTypes.Soccer:
      return 'game-soccer';
    case SportTypes.Hockey:
      return 'game-hockey';
    case SportTypes.Bandy:
      return 'game-bandy';
    case SportTypes.Floorball:
      return 'game-floorball';
    case SportTypes.Handball:
      return 'game-handball';
    case SportTypes.Basketball:
      return 'game-basketball';
    case SportTypes.Tennis:
      return 'game-tennis';
    case SportTypes.WinterOlympic:
      return 'game-winter';
    default:
      return 'game-sport';
  }
};
const isDrawBetDisabled = draw => {
  var _draw$regBetDisabled;
  return ((_draw$regBetDisabled = draw === null || draw === void 0 ? void 0 : draw.regBetDisabled) !== null && _draw$regBetDisabled !== void 0 ? _draw$regBetDisabled : DisableType.Regbet) === DisableType.NotDisabled;
};
const getDrawState = draw => {
  const isRegBetDisabled = isDrawBetDisabled(draw);
  const isOpened = draw.drawStateId === DrawState.Opened;
  const isCancelled = draw.cancelled;
  let state;
  if (!isRegBetDisabled) {
    state = DRAW_STATE.TEMPORARY_CLOSED;
  } else if (isCancelled) {
    state = DRAW_STATE.CANCELLED;
  } else if (!isOpened) {
    state = DRAW_STATE.NOT_OPENED;
  } else if (getDrawStateEnum(draw.drawStateId) === drawStateEnums.LIVE) {
    state = DRAW_STATE.IS_LIVE;
  }
  return state;
};
const getFundInfoToShow = fund => {
  const extraMoney = {};
  if (!fund) {
    return extraMoney;
  }
  const jackpotValues = fund !== null && fund !== void 0 && fund.jackpots ? fund.jackpots[0] : undefined;
  const extraMoneyFromFund = fund !== null && fund !== void 0 && fund.extraMoney && parseInt(fund.extraMoney, 10) > 0 ? fund.extraMoney : undefined;
  const rollOverInFromFund = fund !== null && fund !== void 0 && fund.rolloverIn && parseInt(fund.rolloverIn, 10) > 0 ? fund.rolloverIn : undefined;
  if (jackpotValues && !(jackpotValues !== null && jackpotValues !== void 0 && jackpotValues.defaultJackpot)) {
    extraMoney.isJackpot = true;
    extraMoney.amount = currency(jackpotValues.jackpotAmount);
  } else if (jackpotValues !== null && jackpotValues !== void 0 && jackpotValues.defaultJackpot) {
    extraMoney.isGuaranteeJackpot = true;
    extraMoney.amount = currency(jackpotValues.guaranteedJackpotAmount);
  } else if (extraMoneyFromFund) {
    extraMoney.isExtraMoney = true;
    extraMoney.amount = currency(extraMoneyFromFund);
  } else if (rollOverInFromFund) {
    extraMoney.isRolloverIn = true;
    extraMoney.amount = currency(rollOverInFromFund);
  }
  if (fund.productId === FULLTRAFF && (rollOverInFromFund || extraMoneyFromFund)) {
    extraMoney.isExtraMoneyAndJackpot = true;
    extraMoney.amountForExtraMoneyWithJackpot = currency(rollOverInFromFund) || currency(extraMoneyFromFund);
  }
  return extraMoney;
};
const getLeagueInfo = leaguesAccumulatedInOrder => {
  const hasLeaguesToShow = (leaguesAccumulatedInOrder === null || leaguesAccumulatedInOrder === void 0 ? void 0 : leaguesAccumulatedInOrder.length) > 0;
  if (!hasLeaguesToShow) {
    return null;
  }
  const leagueNameToShow = leaguesAccumulatedInOrder[0].name || leaguesAccumulatedInOrder[0].uniqueLeagueName;
  const hasMoreThanOneLeague = leaguesAccumulatedInOrder.length > 1;
  const leagueToShow = hasMoreThanOneLeague ? "".concat(leagueNameToShow, " +").concat(leaguesAccumulatedInOrder.length - 1) : leagueNameToShow;
  return leagueToShow;
};
const composeTitle = _ref => {
  let {
    drawComment,
    description,
    productId,
    betIndex,
    regCloseTime
  } = _ref;
  if (productId === BOMBEN) {
    let title = drawComment || description;
    if (typeof betIndex === 'number' && betIndex > 0) {
      if (/^Bomben, [234] matcher$/i.test(title) || title.toLowerCase().trim() === 'bomben') {
        title = "Bomben ".concat(betIndex);
      }
    }
    return title;
  }
  if ([POWERPLAY, T8, T8STRYK, T8EXTRA].indexOf(productId) >= 0) {
    return dateFns.format(new Date(regCloseTime), 'EEEE', {
      locale: dateFns.locales.sv
    });
  }
  return "Vecka ".concat(dateFns.getISOWeek(new Date(regCloseTime)));
};
const composeHref = (drawNumber, productId) => {
  let returnUrl = '';
  if ([T8, T8EXTRA, T8STRYK].indexOf(productId) >= 0) {
    returnUrl += "?draw=".concat(drawNumber, "&product=").concat(productId);
  } else {
    returnUrl += "?draw=".concat(drawNumber);
  }
  return returnUrl;
};
const useComposeBetEventsWithParticipants = (betEvents, drawId) => {
  const allParticipants = useSelector(selectAllParticipants);
  return useMemo(() => {
    const uniqueMatchIds = new Set();

    const uniqueBetEvents = betEvents.filter(betEvent => {
      if (betEvent.eventTypeId === EventTypeId.RESULT && uniqueMatchIds.has(betEvent.matchId)) {
        return false;
      }
      uniqueMatchIds.add(betEvent.matchId);
      return true;
    });
    return uniqueBetEvents.map(betEvent => {
      var _betEvent$participant;
      const [homeParticipantId, awayParticipantId] = (_betEvent$participant = betEvent === null || betEvent === void 0 ? void 0 : betEvent.participants) !== null && _betEvent$participant !== void 0 ? _betEvent$participant : [];
      const awayParticipant = allParticipants[awayParticipantId];
      const homeParticipant = allParticipants[homeParticipantId];
      return {
        betEventId: "".concat(drawId, "_").concat(betEvent.eventNumber),
        homeParticipant: {
          participantId: homeParticipantId,
          flagISO: homeParticipant === null || homeParticipant === void 0 ? void 0 : homeParticipant.isoCode,
          name: homeParticipant === null || homeParticipant === void 0 ? void 0 : homeParticipant.name,
          mediumName: homeParticipant === null || homeParticipant === void 0 ? void 0 : homeParticipant.mediumName
        },
        awayParticipant: {
          participantId: awayParticipantId,
          flagISO: awayParticipant === null || awayParticipant === void 0 ? void 0 : awayParticipant.isoCode,
          name: awayParticipant === null || awayParticipant === void 0 ? void 0 : awayParticipant.name,
          mediumName: awayParticipant === null || awayParticipant === void 0 ? void 0 : awayParticipant.mediumName
        }
      };
    });
  }, [allParticipants, betEvents, drawId]);
};
setGlobal('svs.components.tipsen.drawCard.utils', {
  composeHref,
  getDrawState,
  getFundInfoToShow,
  getLeagueInfo,
  composeTitle,
  useComposeBetEventsWithParticipants,
  isDrawBetDisabled,
  getIconBySportType
});

 })(window);